<template>
  <div
    class="v-dropdown-menu-wrapper"
    v-on-click-outside="onClickOutside"
  >
    <div
      class="v-dropdown-menu"
      :class="{
        'v-dropdown-menu-open': isOpenClass,
        disabled: disabled,
        'v-dropdown-menu-form': !isNotForm
      }"
      :aria-label="label.toString()"
      :style="
        open
          ? 'box-shadow: 0px 5px 7px 2px rgba(20, 30, 60, 0.075)'
          : 'box-shadow: none'
      "
    >
      <div
        class="v-dropdown-menu-title v-flex-100"
        :data-test-id="dataTestId && `${dataTestId}-dropdown`"
        @click="toggle"
      >
        <div class="v-dropdown-menu-title--content">
          <slot
            v-if="open"
            name="titleOpen"
          >
            <slot name="title">
              {{ label }}
            </slot>
          </slot>
          <slot
            v-else
            name="title"
          >
            {{ label }}
          </slot>
        </div>
        <icon-old-general-chevron-right
          v-if="!disableChevron"
          :id="`${uid}-chevron`"
          class="v-dropdown-menu-chevron"
        />
      </div>

      <div
        :id="`${uid}-list`"
        class="v-dropdown-menu-list v-flex-100 v-d-flex v-flex-column"
        :class="{
          'v-dropdown-menu-list-form': !isNotForm
        }"
        :data-test-id="dataTestId && `${dataTestId}-dropdown-list`"
        ref="list"
        style="max-height: 0"
      >
        <div
          v-for="(item, index) in items"
          :key="`dropdown-menu-${uid}-${index}`"
          class="v-dropdown-menu-single"
          :ref="setReference(index)"
          @click="slotClick"
        >
          <slot
            v-if="typeof item === 'object'"
            :key="index"
            name="item"
            v-bind="item"
          />
          <slot
            v-else
            name="index"
            v-bind="{ index: index }"
          >
            {{ index }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import { type VElement, vOnClickOutside } from '@arora/common'

import type { VNodeRef } from 'vue'

import { gsap } from 'gsap'

const { closeOnClick, items } = defineProps<
  VElement & {
    items: T[]
    closeOnClick?: boolean
    disableChevron?: boolean
    isNotForm?: boolean
  }
>()

const { hasActivePopup } = storeToRefs(usePopupStore())
function onClickOutside(): void {
  if (open.value) {
    toggle()
  }
}
const firstElementInDropdown = ref<VNodeRef | null | undefined>(null)
const setReference = (index: number): VNodeRef | undefined => {
  return index === items.length - 1 ? firstElementInDropdown : undefined
}
function scrollIntoDropdownPopup(): void {
  if (hasActivePopup.value) {
    firstElementInDropdown.value[0].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    })
  }
}

const duration = 0.1 * Math.pow(1.3, items.length - 1)

function toggle(): void {
  open.value = !open.value

  if (open.value) {
    isOpenClass.value = true
    if (document.querySelector(`#${uid}-chevron`))
      gsap.to(`#${uid}-chevron`, {
        duration: duration,
        ease: 'power2.in',
        rotation: -90,
        scaleY: 0.95
      })

    if (document.querySelector(`#${uid}-list`)) {
      gsap.to(`#${uid}-list`, {
        boxShadow: '0px 5px 7px 2px rgba(20, 30, 60, 0.075)',
        duration: duration,
        ease: 'power2.in',
        maxHeight: 999,
        onComplete: () => {
          scrollIntoDropdownPopup()
        },
        paddingTop: 4
      })
    }
  } else {
    if (document.querySelector(`#${uid}-chevron`))
      gsap.to(`#${uid}-chevron`, {
        duration: duration,
        ease: 'power2.in',
        rotation: 0,
        scaleY: 1
      })

    if (document.querySelector(`#${uid}-list`))
      gsap.to(`#${uid}-list`, {
        boxShadow: 'none',
        duration: duration,
        ease: 'power2.in',
        maxHeight: 0,
        onComplete: () => {
          isOpenClass.value = false
        },
        paddingTop: 0
      })
  }
}

const open = ref<boolean>(false)
const isOpenClass = ref<boolean>(false)

const uid = useId()

function slotClick(): void {
  if (closeOnClick) {
    toggle()
  }
}

const list = ref<HTMLDivElement>()
</script>

<style lang="scss">
@use 'assets/variables';

.v-dropdown-menu-wrapper {
  width: 100%;
  position: relative;
}

.v-dropdown-menu {
  background: variables.$BodyElementsBackground;
  border-radius: variables.$BorderRadius;
  border: 1px solid rgba(125, 125, 125, 0.1);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &-open {
    z-index: 99;
    border-radius: variables.$BorderRadius variables.$BorderRadius 0 0;

    .v-dropdown-menu-list {
      border-radius: 0 0 variables.$BorderRadius variables.$BorderRadius;
    }
  }

  .v-dropdown-menu-chevron {
    height: 12px;
    width: auto;
    min-width: 14px;
  }
}

.v-dropdown-menu-form {
  background: variables.$FormBackgroundNoOpaque;
  color: variables.$BodyTextColor;
}

.v-dropdown-menu-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 12px 16px;
  font-size: 1rem;
  cursor: pointer;
  gap: 12px;

  &--content {
    width: 100%;

    display: flex;
    justify-content: space-between;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.v-dropdown-menu-list {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 99;
  background: variables.$BodyElementsBackground;
  border-radius: variables.$BorderRadius;
}

.v-dropdown-menu-list-form {
  background: variables.$FormBackgroundNoOpaque;
}

.v-dropdown-menu-single {
  text-align: left;
  margin: 4px 0;

  &:hover {
    color: variables.$SecondaryBackgroundColor;
  }
}

.v-popper--theme-arora-dropdown {
  .v-popper__inner {
    background: variables.$PopupBackground;
    color: variables.$PopupColor;
    border-radius: variables.$BorderRadius;
    box-shadow: variables.$FloatShadow;

    padding: 12px;
  }

  .v-popper__arrow-inner {
    border-color: variables.$PopupBackground;
  }

  .v-popper__arrow-outer {
    border-color: variables.$PopupBackground;
  }

  .v-popper__arrow-container {
    display: none;
  }
}
</style>
